
/* homepage */

.Home{
    width: 100%;
    background-image: url('../../util/background.jpg');
    height: 100%;
    background-size: 100% 100%;
}

.home1{
    background-image: url('../../util/backgroundImage.jpg');
    width: 100%;
    height: 1080px;
}

.layer{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
}

#homepage-middle-description{
    font-family: 'Open Sans', sans-serif;
    padding-left: 20px;
}
#homepage-middle-title{
    font-family: 'Fjalla One', sans-serif;
    font-size: 180px;
}

.homepage{
    width: 100%;
    height: 100%;
}

.homepage-top{
    display: flex;
    flex-direction: column;
    padding-top: 300px;
    padding-right: 200px;
    width: 100%;
}

.homepage-top-text{
    color: white;
    text-align: right;
    font-size: 18px;
    font-family: 'Lora', serif;
    letter-spacing: 15px;
}

.homepage-middle{
    padding-left: 300px;
    padding-top: 100px;
    display: flex;
    width: 100%;
}

.homepage-middle-text{
    color: white;
    text-align: start !important;
}

.socialIcon{
    padding: 30px;
    border-radius: 50%;
    border: 1px solid white !important;
}

.homepage-middle-right{
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-right: 50px;
}

.homepage-bottom{
    display: flex;
    justify-content: space-around;
    padding-top: 150px;
    width: 100%;
}

.homepage-bottom-text{
    text-align: center;
    padding: 0 200px;
}

.homepage-mobile-texts{
    display:none;
}

.homepage-mobile-right{
    display: none;
}

.mobile-icons{
    display: none;
}

.homepage-bottom-text-title{
    color: white;
}

/* about */

.About{
    width: 100%;
    text-align: center;
    padding-top: 100px;
}

.about-top-title{
    font-family: 'Fjalla One';
    padding-bottom: 50px;
}

.about-top-description{
    font-family: 'Open Sans';
    padding: 0 50px 30px 50px;
}

.about-bottom{
    display:flex;
    justify-content: space-evenly;
}

.homepage-mobile-bottom{
    display: none;
}

/* Service */

.image {
    position: relative;
    width: 100%;
}

.about-photo1, .about-photo2 {
    display: block;
    width: 100%;
    filter: brightness(75%)
}

.image__overlay{
    position: absolute;
    top: 5.5%;
    left: 4%;
    width: 92%;
    height: 89%;
    color: #ffffff;
    
    font-size: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
    user-select: none;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.2s;
}

.image__overlay2 > * {
    transform: translateY(20px);
    transition: transform 0.2s;
}

.image__overlay:hover{
    opacity: 1;
    font-size: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.image__title{
    font-size: 2em;
    font-weight: bold;
    font-size: 72px;
    position: absolute;
    bottom: 8%;
    right: 8%;
    font-family: 'East Sea Dokdo', cursive;
}

.image__overlay:hover > * {
    transform: translateY(0);
    font-size: 144px;
    top: 30%;
    left: 4%;
    width: 92%;
    height: 40%;
}

/* MENU */

.Menu{
    padding-top: 300px;
}

.menu-top{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.menu-top-title{
    font-family: 'Fjalla One', sans-serif;
    padding-bottom: 10px;
}

.menu-middle {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 10px;
}

.menu-top-description{
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    
}

.menu-middle-button{
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 1px white;
    padding: 0rem 50px;
    margin-bottom:0px;
    color: white;
    height: 3rem;
}

.menu-middle-button:hover,.menu-middle-button.active{
    cursor: pointer;
    color: #9B87EE;
    user-select: none;
}

.menu-middle-button:last-child{
    border-right:0px;
}


.menu-bottom{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 75px;
    padding-bottom: 400px;
}

.menu-bottom-col{
    width: 90%;
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.menu-bottom-row-cost{
    padding-top: 20px;
    color: #9B87EE;
}

.menu-bottom-row-description{
    color: #F2F2F2;
    font-family: 'Open Sans';
}

.menu-bottom-row{
    min-height: 300px;
}

.gallery-vd {
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1700px) {
    .homepage-middle{
        padding-left: 250px;
    }
    .homepage-top{
        padding-right: 150px;
    }

    .homepage-bottom-text{
        padding: 0 175px;
    }
}

@media screen and (max-width: 1600px){
    .image__overlay:hover > * {
        top: 25%;
        }

    .homepage-middle{
        padding-left: 200px;
    }

    .homepage-top{
      padding-right: 125px;
    }

    .homepage-bottom-text{
        padding: 0 150px;
    }
}

@media screen and (max-width: 1450px){
    .image__overlay:hover > * {
        top: 20%;
    }
    
    .homepage-middle{
        padding-left: 150px;
    }

    .homepage-top{
        padding-right: 100px;
    }

    .homepage-bottom-text{
        padding: 0 125px;
    }
}

@media screen and (max-width: 1300px){
    .homepage-middle{
        padding-left: 100px;
    }

    .homepage-top{
        padding-right: 100px;
    }

}


@media screen and (max-width: 1100px){
    .image__title{
        font-size: 40px;
    }

    .image__overlay:hover > * {
        top: 30%;
        font-size: 80px
        }

    .homepage-middle{
        padding-left: 25px;
    }

    .homepage-top{
        padding-right: 100px;
      }

    .homepage-bottom-text{
        padding: 0 100px;
    }
}

@media screen and (max-width: 991px){
    .Home{
        background-image: url('../../util/homeMobileBackground.png');
        height: 100%;
        background-size: cover;
    }
    .home1{
        background-image: url('../../util/homeBanner.jpg');
        width: 100%;
        height: 550px;
        background-size: cover;
    }

    .About, .Menu, .homepage-top, .socialIcon, .homepage-bottom-text {
        display: none !important;
    }

    #homepage-middle-title{
        font-size: 60px;
    }

    #homepage-middle-description{
        padding-left: 0;
    }

    .homepage-middle{
        padding-top: 200px;
    }

    .mobile-icons{
        display: flex;
    }

    .mobile-icon{
        margin: 20px 20px 5px 5px;
        width: 60px !important;
        padding: 20px;
        border-radius: 50%;
        border: 1px solid white !important;
    }

    .homepage-middle-left{
        padding-left: 50px;
        text-align: left;
    }

    .homepage-mobile-right{
        text-align: right;
        padding-right: 30px;
        padding-top: 100px;
        font-family: 'Lora', serif;
        letter-spacing: 7px;
        display: block;
    }

    .homepage-mobile-text{
        color: white;
    }

    .homepage-mobile-bottom{
        padding: 75px 50px;
        text-align: center;
        font-family: 'Open Sans';
        display: block
    }

    .homepage-mobile-bottom-text{
        padding: 50px 0;
    }

    .homepage-mobile-bottom-title{
        padding: 5px;
    }

    .homepage-mobile-bottom-description{
        color: #E0E0E0;
        opacity: 0.5;
    }

    .footer-mobile-bottom{
        padding: 50px 200px;
    }
}

@media screen and (max-width: 800px){
        .homepage-middle-left{
            padding-left: 25px;
        }
        .footer-mobile-flex{
            padding: 0 50px;
        }
        .footer-mobile-bottom{
            padding: 50px 100px;
        }
    }

@media screen and (max-width: 600px){
        .footer-mobile-bottom{
            padding: 50px 50px;
        }
    }



    .linkdeco:active,.linkdeco:hover{
       color: #9B87EE;
    }